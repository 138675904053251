/* nav-bar */
.navbarsection .navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  background-color: #fdf8f1;
}
.navbarsection .navbar .logo {
  width: 85%;
}

.navbarsection .scrollbox {
  display: flex;
  list-style: none;
  /* margin-left: 10rem !important; */
}
.navbarsection .navbar .nav-link {
  font-size: 16px;
  font-weight: 700;
  color: #52331a;
}
.active a {
  color: #d2a13b !important;
}
.navbarsection .nav-link:hover {
  color: #d2a13b;
}
.navbarsection .icon {
  color: #52331a;
  font-size: 160%;
}
.navbarsection .icons {
  display: flex !important;
  list-style: none;
  /* justify-content: end; */
  align-items: center;
  width: 25%;
}
.navbarsection .icon:hover {
  color: #d2a13b;
}
@media only screen and (max-width: 769px) and (min-width: 320px) {
  .navbarsection .container {
    display: flow-root;
  }
  .navbarsection .navbar .logo {
    width: 30%;
  }
  .navbarsection .scrollbox {
    display: block;
    list-style: none;
    margin-left: 0 !important;
  }
  .navbarsection .icons {
    display: flex !important;
    list-style: none;
    margin-left: 20px !important;
  }
}
/* 1320 */
@media only screen and (max-width: 1320px) and (min-width: 769px) {
  .navbarsection .navbar .nav-link {
    font-size: 12px;
    font-weight: 700;
  }
  .who .hourse-box {
    right: 72%;
    position: absolute;
    top: 25.5%;
  }
}

/* hero ============================*/
.herosection .content {
  height: 100vh;
  background-image: url(../../Assets/Images/Aby-hero.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: inherit;
  width: 100%;
  position: relative;
}
.herosection .content {
  margin-top: 7rem;
}
/* .herosection p {
  font-size: 40px;
  color: #443516;
  font-weight: 200;
  margin-left: 40%;
} */
.herosection h2 {
  font-size: 50px;
  color: #52331a;
  font-weight: 700;
  font-family: cursive;
  position: absolute;
  left: 34%;
  top: 14%;
}

@media only screen and (max-width: 769px) and (min-width: 320px) {
  .herosection {
    padding: 0 !important;
    height: 124px;
  }
  .herosection .content {
    padding-top: 60px;
    margin-top: 9rem;
  }
  .herosection h2 {
    font-size: 12px;
    color: #52331a;
    font-weight: 700;
    left: 35%;
    top: 12%;
  }
}
/* About style--------------------------- */

.Sevices .data {
  margin-top: 23%;
}
.Sevices .title-font {
  font-size: 45px;
  color: #683502;
  font-weight: 700;
}
.Sevices .logo-1 {
  width: 50%;
}
.Sevices .logo-2 {
  width: 50%;
}
.Sevices .logo-3 {
  width: 50%;
}
.Sevices .box-serv {
  border: 5px solid #683502;
  border-radius: 20px;
  height: 14rem;
  display: inline-flex;
  justify-content: center;
  transition: all 1.5s cubic-bezier(0.7, -0.5, 0.3, 1.8);
  transform-style: preserve-3d;
}
.Sevices .data:hover .box-serv {
  transform: rotateY(180deg);
}
.Sevices .front-serv,
.back-serv {
  position: relative;
  top: 0;
  left: 0;
  backface-visibility: hidden;
}
.Sevices .front-serv {
  cursor: pointer;
  height: 100%;
}
.Sevices .back-serv {
  transform: rotateY(180deg);
  position: absolute;
  display: flex;
  justify-content: center;
  margin-top: -2px;
  margin-left: -2px;
  color: #fff;
  background-color: #683502;
  border: 5px solid #683502;
  border-radius: 20px;
  height: 103%;
  width: 102%;
}
.Sevices .back-serv h2 {
  font-size: 18px;
  font-weight: 400;
}
.Sevices .service-h4 {
  font-size: 28px;
  color: #683502;
  font-weight: 700;
}
/* updates */
.nav-link.active {
  color: #d2a13b !important;
}
.nav-link {
  font-size: 22px;
  color: #683502;
}

@media only screen and (max-width: 769px) and (min-width: 320px) {
  .Sevices {
    padding-top: 0;
  }
  .Sevices .logo-1 {
    width: 100%;
  }
  .Sevices .logo-2 {
    width: 68%;
  }
  .Sevices .logo-3 {
    width: 80%;
  }
  .Sevices .back-serv h2 {
    font-size: 90%;
    font-weight: 400;
  }
  .Sevices .data p {
    color: #683502;
    font-size: 20px;
    display: flex;
    font-weight: 400;
  }
  .Sevices .title-font {
    font-size: 32px;
    color: #683502;
    font-weight: 700;
  }
}
/* coming */
.who {
  /* background-image: url(../../Assets/Images/value-bg.svg); */
  /* height: 70vh; */
  background-color: #f8f5ee;
  text-align: center;
  position: relative;
}
.who .hourse-box {
  left: -102px;
  position: absolute;
  bottom: -0.7rem;
}
.who .who-title {
  color: #683502;
  font-size: 275%;
  display: flex;
  font-weight: 700;
}
.who p {
  color: #683502;
  font-size: 150%;
  font-weight: 700;
}
.who .slick-prev:before,
.who .slick-next:before {
  color: #683502 !important;
}
/* .who .data-vision .who-title,
  .who .data-vision p {
  
    color: #fff;
  } */
@media only screen and (max-width: 1024px) and (min-width: 320px) {
  /* .who {
        background-color: #683502;
        background-image: none;
      } */
  .who .hourse-box {
    display: none;
  }
  .col-who {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .who .who-title {
    font-size: 23px;
  }
  .who p {
    font-size: 16px;
  }
  .who .icon {
    width: 169%;
    margin-left: -13px;
    margin-top: 9px;
  }
}
@media only screen and (max-width: 1320px) and (min-width: 769px) {
  .who .hourse-box {
    right: 72%;
    position: absolute;
    top: 21.3%;
  }
  .who .row {
    padding: 0 !important;
  }
}
/* vedio */
.vidoe {
  /* height: 100vh; */
  background-image: url(../../Assets/Images/Aby-hero.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: inherit;
  width: 100%;
}
@media only screen and (min-device-width: 300px) and (max-device-width: 812px) {
  .vidoe {
    /* height: 100vh; */
    background-image: url(../../Assets/Images/Aby-hero.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: inherit;
    width: 100%;
  }
  .vidoe video{
      height: auto !important;
  }
  .vedio_box{
    height: auto !important;
  }
}
/* team========== */
.team {
  background-image: url(../../Assets/Images/aby-team.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  /* height: 100vh; */
}
.team .title .title-font {
  font-size: 45px;
  color: #683502;
  font-weight: 700;
}
.team .slideritem {
  margin-bottom: 80px;
}
.team .slick-list {
  margin: 0px 20px;
  /* margin-top: 64px; */
}
.team .img-t {
  margin-left: 18%;
  margin-top: -25%;
}
.team .inside {
  border: none;
  /* border-radius: 25px; */
  background-color: #f4eada;
  text-align: center;
  width: 95%;
  margin-top: 154px;
  height: 29rem;
}
.team .inside h3 {
  color: #52331a;
  font-weight: 700;
  margin-top: 70px;
}
.team .inside label {
  color: #8c5336;
  font-size: 20px;
  display: block;
  font-weight: 700;
}
.team .inside label {
  color: #8c5336;
  font-size: 18px;
  display: block;
  font-weight: 600;
}
.team .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #8e5204;
  font-size: 16px;
}
.team .slick-dots li button:before {
  opacity: 0.75;
  color: #b5b7ac;
  font-size: 16px;
}
.team .slick-prev:before,
.team .slick-next:before {
  color: #8e5204 !important;
}
@media (max-width: 768px) {
  .team .title .title-font {
    font-size: 30px;
    margin-left: 28%;
    color: #443516;
    margin-top: 80px;
    font-weight: 700;
  }
  .team .inside h3 {
    color: #8e5204;
    font-weight: 700;
    font-size: 12px;
  }
  .team .inside label {
    color: #bd8031;
    font-weight: 600;
    font-size: 10px;
  }
  .team .inside p {
    font-size: #000;
    font-weight: 500;
    font-size: 10px;
  }
}
/* sustainability */
.sustainability .content {
  background-color: rgba(244, 234, 218, 1);
  /* height: 50vh; */
}
.sustainability .title .title-font {
  font-size: 45px;
  color: #683502;
  font-weight: 700;
}
.sustainability .content p {
  font-size: 24px;
  font-weight: 600;
  color: rgba(105, 55, 5, 1);
}
@media only screen and (min-device-width: 300px) and (max-device-width: 425px){
  .sustainability .title .title-font {
    font-size: 24px;
    color: #683502;
    font-weight: 700;
  }
  
  .sustainability .content {
    background-color: transparent;
    /* height: 50vh; */
  }
  .plan_web_v {
    display: none !important;
  }
  .plan_mob_v {
    display: block !important;
  }
  .plan_mob_v .box_all{
    border: 0;
    border-radius: 20px;
    width: 100%;
  }
  .sustainability .content p {
    font-size: 20px;
    text-align:center ;
    font-weight: 600;
    color: #fff;
  }
  .plan_mob_v .layer{
    border-radius: 20px;
    background-color: #6835026d;
    color: #fff;
  }
  .plan_mob_v .box_1{
    background-image: url(../../Assets/Images/ab-su-1.svg);
    background-position: center;
    background-repeat: no-repeat;
    transition: transform .2s;
    background-size: cover;
    overflow: hidden;
  }
  .plan_mob_v .box_2{
    background-image: url(../../Assets/Images/ab-su-2.svg);
    background-position: center;
    background-repeat: no-repeat;
    transition: transform .2s;
    background-size: cover;
    overflow: hidden;
  }
  .plan_mob_v .box_3{
    background-image: url(../../Assets/Images/ab-su-3.svg);
    background-position: center;
    background-repeat: no-repeat;
    transition: transform .2s;
    background-size: cover;
    overflow: hidden;
  }
  .plan_mob_v .box_4{
    background-image: url(../../Assets/Images/ab-su-4.svg);
    background-position: center;
    background-repeat: no-repeat;
    transition: transform .2s;
    background-size: cover;
    overflow: hidden;
  }
  /* .plan_mob_v .box_1:hover{
    transform: scale(1.5);
  } */
}
/* ==================last News======================= */
.last_news .title .title-font {
  font-size: 45px;
  color: #683502;
  font-weight: 700;
}
.last_news .NewcardBox{
  border-radius: 25px;
  box-shadow: 0px 4px 3px 0px rgba(82, 51, 26, 0.30);
  overflow: hidden;
  height: 100%;
}
.last_news .img_box1{
  background-image: url(../../Assets/Images/news1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 15rem;
  width: 100%;
}
.last_news .img_box2{
  background-image: url(../../Assets/Images/news2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 15rem;
  width: 100%;
}
.last_news .img_box3{
  background-image: url(../../Assets/Images/news3.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 15rem;
  width: 100%;
}
.last_news span{
  color: #877665;
}
.last_news p{
  color: #623425;
  direction: rtl;
}
.last_news a{
  font-size: 14px;
}
/* sponsers */

/* ================== */
.sponsers .box-serv {
  border: 4px solid #683502;
  border-radius: 20px;
  height: 12rem;
  display: inline-flex;
  justify-content: center;
  transition: all 1.5s cubic-bezier(0.7, -0.5, 0.3, 1.8);
  transform-style: preserve-3d;
}
.sponsers .data:hover .box-serv {
  transform: rotateY(180deg);
}
.sponsers .front-serv,
.back-serv {
  position: relative;
  top: 0;
  left: 0;
  backface-visibility: hidden;
}
.sponsers .front-serv {
  cursor: pointer;
  height: 100%;
  width: 10rem;
}
.sponsers .back-serv {
  transform: rotateY(180deg);
  position: absolute;
  display: flex;
  justify-content: center;
  margin-left: -3px;
  color: #fff;
  background-color: #683502;
  border: 5px solid #683502;
  border-radius: 20px;
  height: 102%;
  width: 102%;
}
.sponsers .back-serv a {
  font-size: 50px;
  color: #fff;
}
/* ================ */
.sponsers .sliders {
  padding-top: 18px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
}
.sponsers .slick-prev:before,
.slick-next:before {
  color: #fff !important;
}
.sponsers .sliders .title-font {
  font-size: 45px;
  color: #443516;
  font-weight: 700;
}

/* .sponsers .slider-trucks{
    animation: scroll 10s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(250px * 14);
    /* width:200%; */
/* } */
.slider-trucks:hover {
  animation-play-state: paused;
}
/* .sponsers .row{
  margin-right: calc(4.5 * var(--bs-gutter-x)) !important;
} */

.sponsers .sliders .line-title {
  width: 80px;
  display: flex;
  margin-left: 41%;
  height: 10px;
  background-color: #d2a13b;
}

.sponsers .data-info p {
  font-size: 11px;
  color: #443516;
  font-weight: 600;
}
@media only screen and (max-width: 1024px) and (min-width: 320px) {
  .sponsers .data {
    padding: 0 !important;
  }
}

.faq .title-font {
  font-size: 45px;
  color: #683502;
  font-weight: 700;
}
.faq .faq-title {
  font-size: 22px;
  color: #683502;
  font-weight: 700;
}
.faq .message {
  width: 100%;
  border: 1px solid #683502;
  border-radius: 20px;
}
.faq .btn-send {
  text-align: center;
  padding: 1% 6%;
  margin-left: 84%;
  background-color: #9a4c02;
  color: #fff;
  border: 0;
  border-radius: 25px;
}
@media only screen and (max-width: 1024px) and (min-width: 320px) {
  .faq .btn-send {
    text-align: center;
    padding: 1% 6%;
    margin-left: 75%;
  }
}

.accordion-button::after {
  background-size: 1rem !important;
}
.accordion-item {
  border: 0;
  margin-bottom: 12px;
}
.accordion-item .accordion-button {
  background-color: #f7f4e9;
  color: #52331a;
  font-size: 24px;
  font-weight: 700;
}
.accordion-body {
  background-color: #f7f4e9;
  border: 0;
  color: #683502;
  text-align: initial;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .sponsers .sliders .title-font {
    font-size: 30px;
    color: #443516;
    font-weight: 700;
  }
  .sponsers .data-info p {
    font-size: 12px;
    color: #443516;
    font-weight: 600;
  }
  .sponsers .faq .line-title {
    margin-left: 38%;
  }
  .accordion-item .accordion-button {
    background-color: #f7f4e9;
    color: #2d2e2e;
    font-size: 20px;
  }
}
